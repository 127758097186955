import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import GlobalContext from '../../../context/GlobalContext';
import API from './../../../api/api';
import jwt from 'jsonwebtoken';
import Select, { components } from 'react-select';
import loader from '../../../assets/image/gif/loader.gif';
import { navigate } from 'gatsby';
import { DebounceInput } from 'react-debounce-input';
import { getOrderJourneyTag } from '../../../utils/OdinOrderHelpers';
import '../../Forms/index.scss';

const CheckAvailability = (props) => {
  const [postCode, setPostCode] = useState('');
  const [postCodeAddress, setPostCodeAddress] = useState({ label: '', value: null });
  const [searchResult, setSearchResult] = useState({
    available: false,
    salesStatus: null,
    loading: false,
    data: null,
  });
  const gContext = useContext(GlobalContext);
  const inputEl = useRef(null);
  const registrationData = gContext.goGetRegistrationData();

  /* Move to REGISTER page when the address is selected in FullScreenSearch */
  useEffect(() => {
    if (
      registrationData?.searchPremiseResults &&
      !registrationData?.personalData &&
      !registrationData?.currentProvider &&
      !registrationData?.checkoutStep
    ) {
      if (typeof window !== 'undefined' && window?.dataLayer) {
        if (typeof window !== 'undefined') {
          window.dataLayer.push({
            postCodeCheckData: registrationData.searchPremiseResults,
          });
        }
      }

      if (
        registrationData?.searchPremiseResults.properties.IsBDUK === "true"
      ) {
        navigate("/voucher")
      } else {
        if (registrationData?.searchPremiseResults.properties.Classification === 'RESIDENTIAL' || registrationData?.searchPremiseResults.properties.Classification === null || !registrationData?.searchPremiseResults?.id) {
          navigate(
            `/register-residential${getOrderJourneyTag(
              registrationData.searchPremiseResults.properties.SalesStatus,
            )}`,
            {
              state: {
                registrationData: registrationData,
              },
            },
          );
        } else {
          navigate(
            `/register-business${getOrderJourneyTag(
              registrationData.searchPremiseResults.properties.SalesStatus,
            )}`,
            {
              state: {
                registrationData: registrationData,
              },
            },
          );
        }
        
      }
    }
  }, [registrationData]);

  if (gContext) {
    gContext.goHeaderMaximized();
  }

  // If Postcode address is selected -> hit the API endpoint.
  useEffect(() => {
    if (props.focusInput) {
      inputEl.current.focus();
    }
    if (postCodeAddress && postCodeAddress.value) {
      console.log('%cDebug: Postcode Address', 'color:royalblue', postCodeAddress);
      setSearchResult({
        ...searchResult,
        loading: true,
      });
      console.log('%c Fetching Premise information...', 'color:RoyalBlue');
      /* 1. Get the Premise for selected Address */
      API.get(`/CrmModule/v2.0/premises/${JSON.parse(postCodeAddress.value).SPRN}`)
        .then((response) => {
          if (response.data.data._sessionId) {
            const token = response.data.data._sessionId;
            let decoded = null;
            try {
              decoded = jwt.verify(token, process.env.GATSBY_JWT_TOKEN);
            } catch (err) {
              decoded = {
                IsBDUK: 'false',
                NetomniaSalesStatus: null,
                SalesStatus: null,
                BuildStatus: 0,
                Providers: null
              };
            }

            response.data.data.properties.NetomniaSalesStatus = decoded.NetomniaSalesStatus;
            response.data.data.properties.SalesStatus = decoded.SalesStatus;
            response.data.data.properties.IsBDUK = decoded.IsBDUK;
            response.data.data.properties.Providers = decoded.Providers;
            response.data.data.properties.BuildStatus = decoded.BuildStatus;
          }

          const skipBooking = response?.data?.data?.properties?.Providers?.indexOf('CITYFIBRE') > -1 ? true : false

          if (typeof document !== "undefined") {
            if (response.data.data.properties.SalesStatus === 'ORDER') {
              document.cookie = "user_rfs=true";
            }
          }

          if (!response.data.data.id) {
            gContext.goSetRegistrationData({
              ...gContext.goGetRegistrationData(),
              skipBooking,
              searchPremiseResults: {
                title: JSON.parse(postCodeAddress.value).FullAddress,
                properties: JSON.parse(postCodeAddress.value),
              },
            });
          } else {
            gContext.goSetRegistrationData({
              ...gContext.goGetRegistrationData(),
              skipBooking,
              searchPremiseResults: response.data.data,
            });
          }

          setTimeout(() => {
            setSearchResult({
              loading: false,
              salesStatus:
                response.data.data.properties.SalesStatus !== null
                  ? response.data.data.properties.SalesStatus
                  : 'REGISTER_INTEREST',
              available: true,
            });
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            setSearchResult({
              loading: false,
              salesStatus: null,
              available: false,
            });
          }, 1000);

          console.log('%c API Error while Getting Premise information', 'color:red');

          gContext.setAPIErrorMsg({
            title: 'Registration Error',
            message: error.response?.data?.message
              ? error.response?.data?.message
              : 'There was a problem accessing our servers. Please try again later.',
          });
          console.log('%c API Error: ', 'color:red', error);
        });
    }
  }, [postCodeAddress]);

  useEffect(() => {
    postCode.length > 0
      ? loadOptionsAsync(postCode)
      : setSearchResult({
        ...searchResult,
        data: null,
      });
  }, [postCode]);

  useEffect(() => {
    checkAgentTokenExpiration()
  }, [])

  const checkAgentTokenExpiration = () => {
    const agentTokenExpiration = localStorage.getItem("agentTokenExpiration");
    
    if (!agentTokenExpiration) {
      gContext.setShowAgentBanner(false)
      return
    }

    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (currentTimestamp >= parseInt(agentTokenExpiration, 10)) {
        localStorage.removeItem('agentToken')
        localStorage.removeItem('agentTokenExpiration')
        localStorage.removeItem('agentEmail')
        gContext.setShowAgentBanner(false)
        return
    } else {
        gContext.setShowAgentBanner(true)
        return
    }
  }

  const loadOptionsAsync = (input) => {
    gContext.goResetRegistrationData()
    setSearchResult({
      ...searchResult,
      loading: true,
    });

    const query = `/CrmModule/v1.0/db/Premise2/search?terms=${input}&fields=properties.PostalCode,properties.PostalCodeNoSpace&schemas=${process.env.GATSBY_POSTCODE_SCHEMA_ID}&page=0&size=100&sort=[{%22properties.DoorNumber%22:{%22order%22:%22asc%22}},{%22properties.SPRN%22:{%22order%22:%22asc%22}}]`;

    return API.get(query)
      .then(function (response) {
        const data = response.data.data.map((category) => ({
          value: JSON.stringify({
            UDPRN: category.properties.UDPRN,
            UMPRN: category.properties.UMPRN,
            SPRN: category.properties.SPRN,
            UPRN: category.properties.UPRN,
            FullAddress: category.properties.FullAddress,
            PostalCode: category.properties.PostalCode,
          }),
          label: category.properties.FullAddress,
        }));
        setSearchResult({
          ...searchResult,
          loading: false,
          data,
        });
        return data;
      })
      .catch(function (error) {
        console.log(error);
        setPostCodeAddress('');
        gContext.setAPIErrorMsg({
          title: 'Registration Error',
          message: error.response?.data?.message
            ? error.response?.data?.message
            : 'There was a problem accessing our servers. Please try again later.',
        });
        setSearchResult({
          ...searchResult,
          loading: false,
        });
      });
  };

  const renderSearchResult = () => {
    return (
      <>
        <div className="availability">
          <DebounceInput
            type="text"
            className="reactSelect filter text-left"
            placeholder="Enter Postcode"
            debounceTimeout={700}
            value={postCode}
            onChange={e => setPostCode(e.target.value)}
          />
          {searchResult.loading &&
            <Col sm={12} className="p-2 mt-3 text-center">
              <img src={loader} alt="loader" className="loader" />
            </Col>
          }
          {searchResult.data && !searchResult.loading &&
            <Select
              className="reactSelect text-left check-availability-options"
              components={{ Control: ControlComponent }}
              isSearchable
              options={searchResult.data}
              placeholder="Select address"
              onChange={option => {
                setPostCodeAddress(option)
              }}
              styles={{
                menu: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: '#ffffff',
                  borderRadius: '12px',
                  border: '2px solid #07131e',
                  padding: '5px'
                }),
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingRight: '15px'
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: state.isFocused ? '#08DE9E' : '#ffffff',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  fontFamily: 'DM Sans',
                  fontSize: '16px',
                  color: '#000000'
                }),
              }}
            />
          }
        </div>
      </>
    );
  }

  const ControlComponent = (props) => <components.Control {...props} />;

  return (
    <div ref={gContext.checkAvailabilitySection}>
      <div className={`${props.bg} check-availability ${props.minimized && 'minimized'} ${props.center && 'center'} flex flex-column ${props.title && 'has-title'}`}>
        {props.title && <h2>{props.title}</h2>}
        {renderSearchResult()}
      </div>
    </div>
  )
};

export default CheckAvailability;
